import type {Dispatch, SetStateAction} from 'react'
import {useCallback, useEffect, useRef, useState} from 'react'
import {Loader} from '@googlemaps/js-api-loader'
import type {Cluster, Marker} from '@googlemaps/markerclusterer'
import {MarkerClusterer} from '@googlemaps/markerclusterer'
import {MapLegends} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {env} from '@/utils/env/client.mjs'
import http from '@/utils/axios'
import type {LatLng} from '@/_new-code/products/disease-map/parasite-tracker/parasite-tracker-module'
import {useDiseaseData} from '@/_new-code/services/disease-api/client'
import type {GetData, GetReturnType} from '@/_new-code/services/disease-api/api'
import {toYYYYMMDD} from '@/_new-code/utilities/dates'
import type {ExtendedBlock} from '@/_new-code/services/kontent-ai/types'
import {LoadingSpinner} from '@/_new-code/products/flexible-web-toolkit/components/loading-spinner'
import {convertMilesToMeters} from '@/_new-code/utilities/distance'
import {logError, logMessage} from '@/services/client-logger'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'
import {LoadingOverlay} from '../../loading-overlay'
import {
	FILL_OPACITY_MAP_CIRCLE,
	MAP_TYPE_ID_ROADMAP,
	MAP_TYPE_ID_TERRAIN,
	MARKER_COLOR_MAP_CIRCLE,
	STROKE_OPACITY_MAP_CIRCLE,
	STROKE_WEIGHT_MAP_CIRCLE,
} from '../../utils/constants'
import {
	getInfoWindowContent,
	getPinSvgString,
	getClusterSvgString,
	getMapType,
} from '../../utils/assets-helper'
import type {PlaceType, SearchHandler} from './search'
import {Search} from './search'
import {HEATMAP, HEATMAP_AND_MARKERS, MARKERS} from './new-parasite-map.mac'

function getCleanAddress(
	addressComponents: google.maps.GeocoderAddressComponent[]
): string {
	return addressComponents
		.filter((item) => !item.types.includes('country'))
		.map((item, index) => (index === 0 ? item.long_name : item.short_name))
		.join(', ')
}

type LegendContentItem = IContentItem<{
	legendName: Elements.TextElement
	color: Elements.TextElement
	lowerBound: Elements.NumberElement
	upperBound: Elements.NumberElement
}>

type LegendsContentItem = IContentItem<{
	legend: Elements.LinkedItemsElement<LegendContentItem>
	subTitle: Elements.TextElement
}>

export type NewParasiteTrackerMapContentItem = IContentItem<{
	parasiteType: Elements.MultipleChoiceElement
	heatmapOpacity: Elements.NumberElement
	layers: Elements.MultipleChoiceElement
	latitude: Elements.NumberElement
	longitude: Elements.NumberElement
	zoomLevel: Elements.NumberElement
	startDate: Elements.DateTimeElement
	endDate: Elements.DateTimeElement
	singleCaseTitle: Elements.TextElement
	singleCaseSubtitle: Elements.TextElement
	multipleCaseTitle: Elements.TextElement
	multipleCaseSubtitle: Elements.TextElement
	placeholderText: Elements.TextElement
	mapLegend: Elements.LinkedItemsElement<LegendsContentItem>
	errorMessage: Elements.TextElement
	countyBorderWidth: Elements.NumberElement
	countyBorderColor: Elements.TextElement
	stateBorderWidth: Elements.NumberElement
	stateBorderColor: Elements.TextElement
	radius: Elements.NumberElement
	mapType: Elements.MultipleChoiceElement
}>

interface SearchLocation {
	lat: number
	lng: number
}

interface Location {
	latitude: number
	longitude: number
	administrativeAreaLevel1: string
	administrativeAreaLevel2: string
	state: string
	county: string
	postalCode: string
	countryCode: string
}

interface CaseData {
	id: string
	diseaseId: string
	speciesId: string
	status: string
	outcome: string
	createdDate: string
	confirmationStatus: string
	lastUpdatedDate: string
	isDeleted: boolean
	location: Location
}

type GroupedCases = Record<string, CaseData[]>

interface Pagination {
	offset: number
	limit: number
	totalRecords: number
}

interface NearbyCasesResponse {
	success: boolean
	data: {
		pagination: Pagination
		data: CaseData[]
	}
}

interface AdvancedMarkerElement
	extends google.maps.marker.AdvancedMarkerElement {
	caseCount?: number
}

interface NearbyDisease {
	lat: number
	lng: number
	selectedRadius: number
	diseases: string
}

export const ParasiteTrackerMap: ExtendedBlock<
	NewParasiteTrackerMapContentItem,
	{
		error: boolean
		setError: (error: boolean) => void
		setShowSummaryText: Dispatch<SetStateAction<boolean | undefined>>
		setReportedCases: Dispatch<SetStateAction<string>>
		setLocationName: Dispatch<SetStateAction<string>>
		theme: string
	}
> = ({
	block: {
		elements: {
			zoomLevel: initialZoomLevel,
			layers,
			longitude,
			latitude,
			heatmapOpacity,
			errorMessage: defaultErrorMessage,
			mapLegend,
			startDate,
			endDate,
			parasiteType,
			placeholderText,
			countyBorderColor,
			countyBorderWidth,
			stateBorderColor,
			stateBorderWidth,
			radius,
			mapType,
			singleCaseTitle,
			singleCaseSubtitle,
			multipleCaseTitle,
			multipleCaseSubtitle,
		},
	},
	error,
	setError,
	setShowSummaryText,
	setReportedCases,
	setLocationName,
	theme,
}) => {
	const [errorMessage, setErrorMessage] = useState(
		defaultErrorMessage ||
			'An unexpected error occurred. Please try again later.'
	)
	const [searchHandler, setSearchHandler] = useState<SearchHandler>()
	const [showLoadingOverlay, setShowLoadingOverlay] = useState<boolean>(false)
	const [mapParvo, setMapParvo] = useState<google.maps.Map | null>(null)
	const [searchLocation, setSearchLocation] = useState<SearchLocation | null>(
		null
	)
	const [renderedMarkers, setRenderedMarkers] = useState<
		AdvancedMarkerElement[]
	>([])
	const [markerCluster, setMarkerCluster] = useState<MarkerClusterer | null>(
		null
	)
	const [showMarkersAndPins, setShowMarkersAndPins] = useState<boolean>(true)
	const mapCircle = useRef<google.maps.Circle | null>(null)
	const radiusInMiles = radius ?? 50
	const validatedMapType = getMapType(mapType[0]?.codename ?? '')

	const isHeatMapOnly = validatedMapType === HEATMAP
	const isHeatMapAndMarkers = validatedMapType === HEATMAP_AND_MARKERS
	const isMarkersOnly = validatedMapType === MARKERS

	const {
		isLoading: isLevel1Loading,
		isFetching: isLevel1Fetching,
		error: level1ApiError,
		data: level1Data,
	} = useDiseaseData({
		// @ts-expect-error -- Diseases will be of correct type
		diseases: parasiteType[0]?.codename.toUpperCase(),
		groupBy: 'administrativeAreaLevel1',
		startDate: startDate ?? undefined,
		endDate: endDate ?? undefined,
		countryCodes: ['US'],
	})

	const {isMobile} = useWindowSize()

	const zoomToPlaceID = useCallback(
		(
			map: google.maps.Map,
			geocoder: google.maps.Geocoder,
			placeId: string
		) => {
			geocoder
				.geocode({placeId})
				.then(({results}: {results: google.maps.GeocoderResult[]}) => {
					const bound = results[0]?.geometry.viewport || null
					const padding = isMobile ? 55 : 155
					if (bound) map.fitBounds(bound, padding)
				})
				.catch(() => {
					logError(`Could not zoom to the place ID${placeId}`)
					setError(true)
				})
		},
		[setError, isMobile]
	)

	const fetchLevel2Data = useCallback(
		async (placeId: string): Promise<GetReturnType> => {
			const endpoint = `/api/disease/group-by-administrative-area?countryCodes=US,us&diseases=${parasiteType[0]?.codename.toUpperCase()}&groupBy=administrativeAreaLevel2&placeId=${placeId}${
				startDate ? `&startDate=${toYYYYMMDD(new Date(startDate))}` : ''
			}${endDate ? `&endDate=${toYYYYMMDD(new Date(endDate))}` : ''}`
			const {data} = await http<GetReturnType>(endpoint)
			return data
		},
		[parasiteType, startDate, endDate]
	)

	// Fetch nearby cases function with TypeScript types
	const fetchNearbyCases = useCallback(
		async ({
			lat,
			lng,
			selectedRadius,
			diseases,
		}: NearbyDisease): Promise<NearbyCasesResponse> => {
			const endpoint = `/api/disease/nearby-cases?lat=${lat}&lng=${lng}&radius=${selectedRadius}&diseases=${diseases}&statuses=New,Approved&administrativeAreaLevel2`
			const {data} = await http<NearbyCasesResponse>(endpoint)
			return data
		},
		[]
	)

	const renderRadiusCircle = useCallback(() => {
		mapCircle.current = new window.google.maps.Circle({
			map: mapParvo,
			center: searchLocation,
			radius: convertMilesToMeters(radiusInMiles),
			strokeColor: MARKER_COLOR_MAP_CIRCLE,
			strokeOpacity: STROKE_OPACITY_MAP_CIRCLE,
			strokeWeight: STROKE_WEIGHT_MAP_CIRCLE,
			fillColor: MARKER_COLOR_MAP_CIRCLE,
			fillOpacity: FILL_OPACITY_MAP_CIRCLE,
		})
	}, [mapParvo, searchLocation, radiusInMiles])

	const renderLocationMarker = useCallback(async () => {
		const {AdvancedMarkerElement} = (await google.maps.importLibrary(
			'marker'
		)) as google.maps.MarkerLibrary

		const {InfoWindow} = (await google.maps.importLibrary(
			'maps'
		)) as google.maps.MapsLibrary

		if (markerCluster) {
			markerCluster.setMap(null)
		}
		renderedMarkers.forEach((marker) => (marker.map = null))
		setRenderedMarkers([])

		const sortCasesByPostCode = (cases: CaseData[]): GroupedCases => {
			return cases.reduce<GroupedCases>((prevCase, currCase) => {
				const tmpCase = {...prevCase}
				const postCode = currCase.location.postalCode
				if (postCode) {
					if (!tmpCase[postCode]) {
						tmpCase[postCode] = []
					}
					tmpCase[postCode]?.push(currCase)
				}
				return tmpCase
			}, {})
		}

		const payload = {
			lat: searchLocation?.lat || 0,
			lng: searchLocation?.lng || 0,
			selectedRadius: radiusInMiles || 0,
			diseases: parasiteType[0]?.codename.toUpperCase() || '',
		}

		const fetchedCases = await fetchNearbyCases(payload)

		const nearbyCasesData = sortCasesByPostCode(fetchedCases.data.data)

		const locations = Object.entries(nearbyCasesData)
		const infoWindow = new InfoWindow()

		const parser = new DOMParser()
		const markers = locations.map((item) => {
			const caseData: CaseData = item[1][0] || ({} as CaseData)
			const {location} = caseData
			const numOfCases: number = item[1].length

			const pinSvgString: string = getPinSvgString(
				numOfCases,
				MARKER_COLOR_MAP_CIRCLE
			)

			const pinSvg = parser.parseFromString(
				pinSvgString,
				'image/svg+xml'
			).documentElement
			const marker: AdvancedMarkerElement = new AdvancedMarkerElement({
				position: {lat: location.latitude, lng: location.longitude},
				map: mapParvo,
				content: pinSvg,
			})

			marker.addListener('click', () => {
				infoWindow.close()
				infoWindow.setContent(
					getInfoWindowContent(
						numOfCases,
						singleCaseTitle,
						singleCaseSubtitle,
						multipleCaseTitle,
						multipleCaseSubtitle,
						item[0]
					)
				)
				infoWindow.open(marker.map, marker)
			})
			marker.caseCount = numOfCases
			return marker
		})

		setRenderedMarkers(markers)

		const newMarkerCluster = new MarkerClusterer({
			map: mapParvo,
			markers,
			renderer: {
				render: (cluster: Cluster) => {
					const clusterCount =
						cluster.markers?.reduce(
							(
								a: AdvancedMarkerElement | Marker | number,
								b: AdvancedMarkerElement | Marker | number
							) => {
								const aMarker = a as
									| AdvancedMarkerElement
									| number
								const bMarker = b as
									| AdvancedMarkerElement
									| number

								if (
									typeof aMarker === 'object' &&
									aMarker.caseCount &&
									typeof bMarker === 'object' &&
									bMarker.caseCount
								) {
									return (
										Number(aMarker.caseCount) +
										Number(bMarker.caseCount)
									)
								}
								if (
									typeof aMarker === 'number' &&
									typeof bMarker === 'object' &&
									'caseCount' in bMarker &&
									bMarker.caseCount
								) {
									return aMarker + Number(bMarker.caseCount)
								}
								if (
									typeof aMarker === 'object' &&
									'caseCount' in aMarker &&
									typeof aMarker.caseCount === 'number'
								) {
									return aMarker.caseCount + Number(bMarker)
								}

								return a
							},
							0
						) || 0

					const clusterSvgString = getClusterSvgString(
						MARKER_COLOR_MAP_CIRCLE,
						clusterCount as number
					)
					const clusterSvg = parser.parseFromString(
						clusterSvgString,
						'image/svg+xml'
					).documentElement
					return new AdvancedMarkerElement({
						position: cluster.position,
						map: mapParvo,
						content: clusterSvg,
					})
				},
			},
		})

		newMarkerCluster.render()

		setMarkerCluster(newMarkerCluster)
	}, [
		markerCluster,
		renderedMarkers,
		searchLocation?.lat,
		searchLocation?.lng,
		radiusInMiles,
		parasiteType,
		fetchNearbyCases,
		mapParvo,
		singleCaseTitle,
		singleCaseSubtitle,
		multipleCaseTitle,
		multipleCaseSubtitle,
	])

	const renderCircleAndMarkers = async (
		map: google.maps.Map,
		searchedLocation: SearchLocation
	): Promise<void> => {
		await renderLocationMarker()
		renderRadiusCircle()
		if (isHeatMapAndMarkers) {
			mapParvo && mapParvo.setMapTypeId(MAP_TYPE_ID_TERRAIN)
		}
		map.panTo(searchedLocation)
		map.setZoom(8)

		if (isHeatMapAndMarkers && mapParvo) {
			window.google.maps.event.addListener(
				mapParvo,
				'zoom_changed',
				() => {
					const zoomLevel = mapParvo.getZoom() || 0
					if (zoomLevel > 6) {
						mapParvo.setMapTypeId(MAP_TYPE_ID_TERRAIN)
						setShowMarkersAndPins(true)
					} else {
						mapParvo.setMapTypeId(MAP_TYPE_ID_ROADMAP)
						setShowMarkersAndPins(false)
					}
				}
			)
		}
	}

	useEffect(() => {
		let hoveredPlaceId: string | null = null
		let selectedLevel1Id: string | null = null
		let selectedLevel2Id: string | null = null
		const loader = new Loader({
			apiKey: env.NEXT_PUBLIC_MAP_API_KEY,
			version: 'weekly',
		})
		void loader.importLibrary('maps').then(({Map}) => {
			const parvoMap = document.getElementById('parvomap')

			// If the map isn't in the DOM, or data hasn't been fetched, exit early
			if (!parvoMap || !level1Data?.success) return

			// Initialize geocoder, map, and get handle on each feature layer
			const geocoder = new google.maps.Geocoder()
			const map = new Map(parvoMap, {
				mapId: env.NEXT_PUBLIC_PARASITE_MAP_STYLE_ID,
				center: {
					lat: latitude ?? 52.5555,
					lng: longitude ?? 0,
				},
				zoom: initialZoomLevel ?? 8,
				streetViewControl: false,
				mapTypeControl: false,
				clickableIcons: false,
				keyboardShortcuts: false,
				mapTypeId: isMarkersOnly
					? MAP_TYPE_ID_TERRAIN
					: MAP_TYPE_ID_ROADMAP,
			})
			const featureLayerLevel1 = map.getFeatureLayer(
				google.maps.FeatureType.ADMINISTRATIVE_AREA_LEVEL_1
			)
			const featureLayerLevel2 = map.getFeatureLayer(
				google.maps.FeatureType.ADMINISTRATIVE_AREA_LEVEL_2
			)
			let level2Data: GetData | null

			function getHeatmapStyles(
				data: GetData,
				placeId: string,
				placeType: PlaceType
			): google.maps.FeatureStyleOptions {
				const numOfCases = data[placeId]?.worstAffectedArea || 0
				const legendData = mapLegend[0]?.elements.legend || []
				let fillColor
				for (const legend of legendData) {
					if (
						numOfCases >= (legend.elements.lowerBound ?? 0) &&
						numOfCases < (legend.elements.upperBound ?? 0)
					) {
						fillColor = legend.elements.color
					}
				}

				// Add hover effect and ignore the selected level2 placeId, since it will have a border around it
				if (
					hoveredPlaceId !== selectedLevel2Id &&
					hoveredPlaceId === placeId &&
					numOfCases > 0
				) {
					return {
						strokeColor: '#2772ce',
						strokeOpacity: 1,
						strokeWeight: 3,
						fillColor,
						fillOpacity: 1,
					}
				}

				let strokeColor
				let strokeWeight
				if (placeType === 'administrative_area_level_1') {
					strokeColor = stateBorderColor || undefined
					strokeWeight = stateBorderWidth || undefined
				} else if (placeId === selectedLevel2Id) {
					strokeColor = 'black'
					strokeWeight = 2
				} else {
					strokeColor = countyBorderColor || undefined
					strokeWeight = countyBorderWidth || undefined
				}

				return {
					fillColor,
					fillOpacity: heatmapOpacity ?? 1,
					strokeColor,
					strokeOpacity: 1,
					strokeWeight,
				}
			}

			function render(): void {
				const renderedLayers = layers.map((e) => e.codename)

				// Render level 1
				featureLayerLevel1.style = (featureStyleFunctionOptions) => {
					// Check if level 1 data is provided and that the layer is toggled
					if (
						!level1Data?.success ||
						(renderedLayers.length > 0 &&
							!renderedLayers.includes('administrative_layer_1'))
					)
						return

					const data = level1Data.data
					const {placeId} =
						featureStyleFunctionOptions.feature as google.maps.PlaceFeature

					// If a level 1 place is selected, make it invisible so that level 2 heatmap can be rendered
					if (placeId === selectedLevel1Id) {
						return {
							fillColor: undefined,
							fillOpacity: undefined,
							strokeColor: 'black',
							strokeOpacity: 1,
							strokeWeight: 2,
						}
					}

					// Paint heatmap according to worstAffectedArea
					return getHeatmapStyles(
						data,
						placeId,
						'administrative_area_level_1'
					)
				}

				// Render level 2
				featureLayerLevel2.style = (featureStyleFunctionOptions) => {
					const {placeId} =
						featureStyleFunctionOptions.feature as google.maps.PlaceFeature

					// If there is no level 2 data, or if we have not selected a level 1 place, or if the layer is not enabled, exit
					if (
						!level2Data ||
						!selectedLevel1Id ||
						!level2Data[placeId] ||
						(renderedLayers.length > 0 &&
							!renderedLayers.includes('administrative_layer_2'))
					) {
						return {
							fillColor: undefined,
							fillOpacity: undefined,
							strokeColor: undefined,
							strokeOpacity: undefined,
							strokeWeight: undefined,
						}
					}

					return getHeatmapStyles(
						level2Data,
						placeId,
						'administrative_area_level_2'
					)
				}
			}

			// Display error message if map falls back to Raster rendering
			// See: https://developers.google.com/maps/documentation/javascript/webgl/support
			map.addListener('renderingtype_changed', () => {
				const renderingType = map.getRenderingType()

				if (renderingType === google.maps.RenderingType.RASTER) {
					setError(true)
					setErrorMessage(
						'Sorry, we cannot display the ParvoTrack Map, as your device or browser is not supported. Try updating your device and browser and try again.'
					)
					void fetch('/api/disease/rendering-issue')
				}
			})

			// Setup listener for click and zoom effect
			featureLayerLevel1.addListener(
				'click',
				async (e: {
					features: {
						placeId: string
					}[]
				}) => {
					const placeId = e.features[0]?.placeId

					if (!placeId) {
						logMessage(
							'PlaceId not found for feature layer 1 click lister!'
						)
						return
					}

					// Get data
					const numberOfCases =
						level1Data.data[placeId]?.numberOfCases

					if (!numberOfCases) {
						logMessage(
							'No cases found for feature layer 1 click lister!'
						)
						return
					}

					setShowLoadingOverlay(true)
					const res = await fetchLevel2Data(placeId)

					level2Data = res.success ? res.data : {}
					selectedLevel1Id = placeId
					selectedLevel2Id = null

					// Make rendering changes
					setShowSummaryText(true)
					setReportedCases(String(numberOfCases))
					zoomToPlaceID(map, geocoder, placeId)
					void geocoder.geocode({placeId}).then(({results}) => {
						const address = getCleanAddress(
							results[0]?.address_components ?? []
						)
						setLocationName(address || 'the selected area')
					})
					render()
					setShowLoadingOverlay(false)
				}
			)

			// Setup listener for clicking on a level 2 place
			featureLayerLevel2.addListener(
				'click',
				(e: {
					features: {
						/** Place ID */
						placeId: string
					}[]
				}) => {
					const placeId = e.features[0]?.placeId

					if (!placeId) {
						logMessage(
							'PlaceId not found for feature layer 2 click lister!'
						)
						return
					}

					setShowSummaryText(true)
					setReportedCases(
						level2Data
							? String(level2Data[placeId]?.numberOfCases ?? '0')
							: '0'
					)
					selectedLevel2Id = placeId

					void geocoder.geocode({placeId}).then(({results}) => {
						const address = getCleanAddress(
							results[0]?.address_components ?? []
						)
						setLocationName(address || 'the selected area')
					})
					render()
				}
			)

			// Setup zoom listener to go back to feature layer level 1 ("state level") after zooming out
			map.addListener('zoom_changed', () => {
				const currentZoomLevel = map.getZoom()
				if (!currentZoomLevel) return

				if (currentZoomLevel <= (initialZoomLevel ?? 8)) {
					selectedLevel1Id = null
					setShowSummaryText(false)
					setReportedCases('')
					render()
				}
			})

			// Setup mouse listeners for hover highlighting effect
			function handleHover(event: {features: {placeId: string}[]}): void {
				const placeId = event.features[0]?.placeId
				if (!placeId) {
					logMessage('PlaceId not found for handleHover function!')
					return
				}

				hoveredPlaceId = placeId
				render()
			}

			featureLayerLevel1.addListener('mousemove', handleHover)
			featureLayerLevel2.addListener('mousemove', handleHover)

			async function handleSearch(
				location: LatLng,
				placeType: PlaceType
			): Promise<void> {
				const {results} = await geocoder.geocode({location})

				const place = results.find(({types}) =>
					types.includes(placeType)
				)

				if (!place) {
					logMessage(
						`Place not found for ${placeType} in handleSearch function!`
					)
					return
				}

				switch (placeType) {
					case 'administrative_area_level_1': {
						if (!level1Data?.success) return

						const numberOfCases =
							level1Data.data[place.place_id]?.numberOfCases ?? 0

						setShowLoadingOverlay(true)
						const res = await fetchLevel2Data(place.place_id)

						level2Data = res.success ? res.data : {}
						selectedLevel1Id = place.place_id

						// Make rendering changes
						setShowSummaryText(true)
						setReportedCases(String(numberOfCases))
						if (isHeatMapOnly) {
							zoomToPlaceID(map, geocoder, place.place_id)
						}
						setLocationName(
							getCleanAddress(place.address_components)
						)
						setMapParvo(map)
						setSearchLocation({
							lat: place.geometry.location.lat(),
							lng: place.geometry.location.lng(),
						})
						setShowLoadingOverlay(false)
						break
					}

					case 'administrative_area_level_2': {
						const level1Place = results.find(({types}) =>
							types.includes('administrative_area_level_1')
						)

						const level2Place = results.find(({types}) =>
							types.includes('administrative_area_level_2')
						)

						if (!level1Place) return

						setShowLoadingOverlay(true)
						const res = await fetchLevel2Data(level1Place.place_id)

						level2Data = res.success ? res.data : {}
						selectedLevel1Id = level1Place.place_id
						selectedLevel2Id = level2Place?.place_id || null
						const numberOfCases =
							level2Data[place.place_id]?.numberOfCases ?? 0

						// Make rendering changes
						setShowSummaryText(true)
						setReportedCases(String(numberOfCases))
						if (isHeatMapOnly) {
							zoomToPlaceID(map, geocoder, place.place_id)
						}
						setLocationName(
							getCleanAddress(place.address_components)
						)
						setMapParvo(map)
						setSearchLocation({
							lat: place.geometry.location.lat(),
							lng: place.geometry.location.lng(),
						})
						setShowLoadingOverlay(false)
						break
					}

					default: {
						selectedLevel1Id = null
						setShowSummaryText(false)
						setReportedCases('')
						zoomToPlaceID(map, geocoder, place.place_id)
						break
					}
				}

				render()
			}
			setSearchHandler(() => handleSearch)

			// Do initial render
			render()
		})
	}, [
		fetchLevel2Data,
		heatmapOpacity,
		latitude,
		level1Data,
		longitude,
		mapLegend,
		setError,
		setLocationName,
		setReportedCases,
		setShowSummaryText,
		initialZoomLevel,
		zoomToPlaceID,
		stateBorderColor,
		stateBorderWidth,
		countyBorderColor,
		countyBorderWidth,
		layers,
		isHeatMapOnly,
		isMarkersOnly,
	])

	useEffect(() => {
		if (mapParvo && searchLocation && !isHeatMapOnly) {
			void renderCircleAndMarkers(mapParvo, searchLocation)
		}

		return () => {
			if (mapCircle.current) {
				mapCircle.current.setMap(null)
			}

			if (markerCluster) {
				markerCluster.setMap(null)
			}
			renderedMarkers.forEach((marker) => (marker.map = null))
			setRenderedMarkers([])
		}
	}, [searchLocation, mapCircle, isHeatMapOnly])

	useEffect(() => {
		if (!showMarkersAndPins) {
			if (mapCircle.current) {
				mapCircle.current.setMap(null)
			}

			if (markerCluster) {
				markerCluster.setMap(null)
			}
			renderedMarkers.forEach((marker) => (marker.map = null))
		}

		if (showMarkersAndPins) {
			if (mapCircle.current) {
				mapCircle.current.setMap(mapParvo)
			}

			if (markerCluster) {
				markerCluster.setMap(mapParvo)
			}
			renderedMarkers.forEach((marker) => (marker.map = mapParvo))
		}
	}, [mapParvo, markerCluster, renderedMarkers, showMarkersAndPins])

	return (
		<div
			className="relative"
			data-kontent-element-codename="parasite_tracker_map"
		>
			{isLevel1Loading || isLevel1Fetching ? (
				<div className="flex h-[350px] w-full animate-pulse items-center justify-center rounded bg-elanco-blue bg-opacity-20 p-5 sm:h-[700px]">
					<LoadingSpinner theme="blue" />
				</div>
			) : null}
			{!isLevel1Fetching && !isLevel1Loading && (
				<>
					{error || Boolean(level1ApiError) ? (
						<div className="absolute bottom-0 left-0 right-0 top-0 z-50 flex justify-center bg-black bg-opacity-50">
							<div className="m-5 flex self-center rounded-md border border-red-500 bg-red-300 p-3">
								<span className="text-s text-center font-semibold">
									{errorMessage}
								</span>
							</div>
						</div>
					) : null}
					{showLoadingOverlay ? (
						<LoadingOverlay color="#ffffff" />
					) : null}
					<div className="flex justify-center">
						{searchHandler ? (
							<Search
								onError={() => {
									setError(true)
								}}
								onLoading={(value) => {
									setShowLoadingOverlay(value)
								}}
								onSearchApi={searchHandler}
								placeholderText={placeholderText || 'Search'}
								theme={theme}
							/>
						) : null}
					</div>
					<div
						className="h-full min-h-[700px] w-full"
						id="parvomap"
					/>
					{mapLegend[0] ? (
						<div className="absolute bottom-5 left-5 z-40 w-80">
							<div data-kontent-element-codename="parasite_tracker_legend">
								<MapLegends
									legends={mapLegend[0].elements.legend.map(
										({elements: {color, legendName}}) => ({
											color,
											legend_name: legendName,
										})
									)}
									subTitle={mapLegend[0].elements.subTitle}
								/>
							</div>
						</div>
					) : null}
				</>
			)}
		</div>
	)
}
